'use client'

import { type ReactNode } from 'react'
import { darkThemeClass, lightThemeClass } from '@/styles/theme.css'
import { ThemeProvider } from 'next-themes'
import { SWRConfig } from 'swr'
import { MediaContextProvider } from '../MediaQuery/MediaQuery'

const fetcher = async url => await fetch(url).then(async r => await r.json())

export default function Providers ({ children }: { children: ReactNode }) {
  return (
    <SWRConfig value={{ fetcher }}>
      <MediaContextProvider>
        <ThemeProvider
          attribute='class'
          enableSystem={false}
          value={{
            light: lightThemeClass,
            dark: darkThemeClass,
          }}
        >
          {children}
          </ThemeProvider>
      </MediaContextProvider>
    </SWRConfig>
  )
}
